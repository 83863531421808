<template>
    <div class="container">
        <div class="row">
            <facility-filter
                v-model="currentFilter"
                class="col-md-3"
                :filters="filters"
                :busy="busy"
            />
            <div
                v-if="!busy"
                class="col-md-9"
            >
                <h1>
                    Facility Rental
                    <a
                        :href="amenitiesUrl"
                        class="btn btn-default btn-xs pull-right"
                    >
                        Switch to Amenities
                    </a>
                </h1>

                <div v-if="filteredSites.length">
                    <site-card
                        v-for="site in filteredSites"
                        :key="site.id"
                        :site="site"
                        :view-url-format="viewUrl"
                    />
                </div>
                <div v-else>
                    <h2>We are sorry but could not find any sites matching the search criteria you entered. Please try your search again.</h2>
                </div>
            </div>
            <div
                v-else
                class="col-md-9"
            >
                <h2 class="text-center">
                    Loading Campsites
                    <i
                        v-if="busy"
                        class="fa fa-spinner fa-spin"
                    />
                </h2>
            </div>
        </div>
    </div>
</template>

<script>
import FacilityFilter from './facility-view-filter'
import SiteCard from './site-card'

export default {
    components: {
        FacilityFilter,
        SiteCard,
    },
    props: {
        filters: {
            type: Object,
            required: true,
        },
        urls: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        sites: [],
        busy: false,
        currentFilter: {
            'camp-property': {},
            'facility-type': {},
            'season-usage': {},
            'cost-type': {},
            'usage-type': {},
            'id': {}
        }
    }),

    computed: {
        sitesUrl () {
            return _.get(this.urls, 'facilitySites')
        },
        amenitiesUrl () {
            return _.get(this.urls, 'amenities')
        },
        viewUrl () {
            return _.get(this.urls, 'view')
        },
        filteredSites () {
            return _.chain(this.sites)
                .filter(this.isInFilter('camp-property', 'campsite_area_id', 'number'))
                .filter(this.isInFilter('facility-type', 'facility_type', 'string'))
                .filter(this.isInFilter('season-usage', 'season_usage', 'string'))
                .filter(this.isInFilter('cost-type', 'cost_type', 'string'))
                .filter(this.isUsageType('usage-type', 'camping_sleeping_quarters'))
                .filter(this.isFacility('id', 'id'))
                .value()
        },
    },
    created () {
        this.requestSites()
    },
    methods: {
        requestSites () {
            this.busy = true
            $.get(this.sitesUrl)
                .done(this.updateSites)
                .always(() => {
                    this.busy = false
                })
        },
        updateSites (data) {
            this.sites = _.cloneDeep(data)
        },
        // Filter Methods
        isInFilter (selected, key, type) {
            const filters = _.get(this.currentFilter, selected, {})
            return site => {
                const value = _.get(site, key)
                const filterKeys = _.chain(filters)
                    .cloneDeep()
                    .pickBy()
                    .keys()
                    .map(item => (type === 'string') ? item : _.toSafeInteger(item))
                    .value()
                return _.isEmpty(filterKeys) ? true : _.includes(filterKeys, value)
            }
        },
        isUsageType (selected, key) {
            const filters = _.get(this.currentFilter, selected, {})
            return site => {
                const value = _.get(site, key)
                const usage = _.chain(filters)
                    .pickBy()
                    .value()
                return _.isEmpty(usage) ? true : value
            }
        },
        isFacility (selected, key) {
            const filters = _.get(this.currentFilter, selected, {})
            const id = _.toSafeInteger(filters.id)
            return site => {
                const match = _.get(site, key) === id
                return _.isEmpty(filters) ? true : match
            }
        },
    }
}
</script>
