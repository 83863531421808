<template>
    <div>
        <div class="row">
            <!-- Panel for Payments -->
            <div class="col-lg-12">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        Payments
                    </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Payment Method*</th>
                                <th>Amount*</th>
                                <th>Name on Card/Check*</th>
                                <th>Check/Receipt #</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(payment, index) in payments">
                                <td class="transaction-item">
                                    <div class="form-group">
                                        <select
                                            id="payment-method"
                                            v-model="payment.payment_method"
                                            class="form-control"
                                            :name="'paymentDetails[' + index + '][payment_method]'"
                                        >
                                            <option
                                                value=""
                                                selected="selected"
                                                disabled="disabled"
                                            >
                                                Select Payment Method
                                            </option>
                                            <option
                                                v-if="payment.payment_method"
                                                :value="payment.payment_method"
                                                selected
                                            >
                                                {{ payment.payment_method }}
                                            </option>
                                            <option
                                                v-for="item in updateSelection(payment)"
                                                :value="item.payment_type"
                                            >
                                                {{ item.payment_type }}
                                            </option>
                                        </select>
                                    </div>
                                    <input
                                        v-model="payment.id"
                                        type="hidden"
                                        :name="'paymentDetails[' + index + '][id]'"
                                    >
                                </td>
                                <td class="transaction-item">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-addon">
                                                $
                                            </div>
                                            <input
                                                v-model="payment.amount"
                                                type="number"
                                                step="0.01"
                                                :name="'paymentDetails[' + index + '][amount]'"
                                                class="form-control"
                                                @blur="updateTotalPayments()"
                                            >
                                        </div>
                                    </div>
                                </td>
                                <td class="transaction-item">
                                    <div class="form-group">
                                        <input
                                            v-model="payment.payment_name"
                                            type="text"
                                            :name="'paymentDetails[' + index + '][payment_name]'"
                                            class="form-control"
                                        >
                                    </div>
                                </td>
                                <td class="transaction-item">
                                    <div class="form-group">
                                        <input
                                            v-model="payment.detail"
                                            type="text"
                                            :name="'paymentDetails[' + index + '][detail]'"
                                            class="form-control"
                                        >
                                    </div>
                                </td>
                                <td class="align-top">
                                    <a
                                        class="btn btn-danger"
                                        href="#"
                                        @click.prevent="deletePayment(index)"
                                    >
                                        <i class="fa fa-trash-o" />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- Stripe -->
                    <table
                        id="stripe-payment"
                        class="table"
                    >
                        <thead>
                            <tr>
                                <th for="card-element">
                                    Credit Card or Debit Card
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="transaction-item">
                                    <div class="form-group col-sm-6">
                                        <div id="card-element">
                                            <!-- a Stripe Element will be inserted here. -->
                                        </div>
                                        <!-- Used to display form errors -->
                                        <div
                                            id="card-errors"
                                            role="alert"
                                        />
                                        <input
                                            type="hidden"
                                            name="address_country"
                                            value="us"
                                        >
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="panel-footer clearfix">
                        <a
                            id="add-payment"
                            href="#"
                            class="btn btn-default pull-right"
                            @click.prevent="addPayment"
                        >
                            <i
                                class="fa fa-plus"
                                aria-hidden="true"
                            />
                            Add Payment
                        </a>
                    </div>
                    <input
                        v-for="payment in deletedPayments"
                        type="hidden"
                        :value="payment.id"
                        name="deletedPayments[]"
                    >
                </div>
            </div>
            <!-- ---------- End Panel for payments -->
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>Total Obligations:</td>
                                <td>${{ totalCharges }}</td>
                            </tr>
                            <tr>
                                <td>Total Payments:</td>
                                <td>${{ totalPayments }}</td>
                            </tr>
                            <tr>
                                <td><strong>Balance:</strong></td>
                                <td><strong>${{ balance }}</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['balanceData', 'transactionItems', 'paymentDetails', 'transactionDetails', 'paymentMethods'],

    data () {
        return {
            deletedPayments: [],
            payments: [],
            balance: (this.balanceData.totalObligations - this.balanceData.totalPayments),
            totalCharges: this.balanceData.totalObligations,
            totalPayments: this.balanceData.totalPayments,
        }
    },

    // Initializes arrays with values if editing, sets empty object if creating
    mounted () {
        if (this.paymentDetails.length > 0) {
            this.payments = this.paymentDetails
            this.updateTotalPayments()
        } else {
            this.addPayment()
        }
    },

    methods: {
        addPayment () {
            this.payments.push({ id: null, payment_method_id: null, amount: 0, payment_name: null, detail: null })
        },

        deletePayment (index) {
            // Checks if the charge existed before the session was created or if it was another row added
            if (this.payments[index].id) {
                this.deletedPayments.push(this.payments[index])
            }

            this.payments.splice(index, 1)
            this.updateTotalPayments()
        },

        updateSelection (payment) {
            // Filters payment method selection to prevent duplicates
            var methods = _.filter(this.paymentMethods, function (item) {
                return item.payment_type !== payment.payment_method
            })
            return methods
        },

        updateTotalPayments () {
            var totalPayments = this.balanceData.totalPayments
            this.payments.forEach(function (payment) {
                totalPayments += Number(payment.amount)
            })

            this.totalPayments = totalPayments

            this.updateBalance()
        },

        updateBalance () {
            this.balance = this.totalCharges - this.totalPayments
        }
    }
}
</script>
