<template>
    <div class="filter">
        <form>
            <h3>Filter By</h3>
            <div
                v-for="filter, key in filters"
                :key="key"
                class="form-group"
            >
                <h5>{{ filter.name }}</h5>
                <div
                    v-for="filterValue in filter.values"
                    :key="filterValue.id"
                    class="checkbox"
                >
                    <label v-if="filterValue.id !== 'id'">
                        <input
                            type="checkbox"
                            :disabled="busy"
                            :value="filterValue.id"
                            @input="updateCheckbox(key, $event)"
                        >
                        <span>{{ filterValue.name }}</span>
                    </label>
                    <select
                        v-else
                        v-model="facility"
                        class="filter-campsites"
                        :disabled="busy"
                    >
                        <option disabled>
                            Select a facility
                        </option>
                        <option
                            v-for="option, index in filterValue.options"
                            :key="index"
                            :value="index"
                        >
                            {{ option }}
                        </option>
                    </select>
                </div>
            </div>
            <div>
                <h5>Total Number of Participants</h5>
                <div class="form-group">
                    <label class="control-label">Youth</label>
                    <input
                        type="text"
                        class="form-control"
                        :value="value.participants.youth"
                        @input="update('participants.youth', $event.target.value)"
                    >
                </div>

                <div class="form-group">
                    <label class="control-label">Adult</label>
                    <input
                        type="text"
                        class="form-control"
                        :value="value.participants.adult"
                        @input="update('participants.adult', $event.target.value)"
                    >
                </div>
            </div>

            <div class="btn-group">
                <button
                    :disabled="busy"
                    class="btn btn-warning"
                    @click.stop.prevent="reset"
                >
                    Clear Filters
                </button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        busy: {
            type: Boolean,
            default: () => false,
        },
    },

    computed: {
        facility: {
            get () {
                return _.get(this.value, 'id.id', '')
            },
            set (value) {
                this.update('id.id', value)
            },
        }
    },

    methods: {
        updateCheckbox (key, event) {
            const checked = event.target.checked
            const value = event.target.value
            this.update([key, value], checked)
        },
        update (path, value) {
            const filter = _.chain(this.value)
                .cloneDeep()
                .set(path, value)
                .value()
            this.$emit('input', filter)
        },
        reset () {
            this.$emit('input', {
                'sleeping-capacity': {},
                'amenities': {},
                'cost': {},
                'participants': {
                    adult: '',
                    youth: ''
                },
                'dates': {
                    arrival_date: '',
                    departure_date: ''
                },
                'id': {}
            })
        },
    },
}
</script>
