<template>
    <div class="container">
        <div class="row">
            <amenity-filter
                v-model="currentFilter"
                :filters="filters"
                :busy="busy"
                class="col-md-3"
            />
            <div
                v-if="!busy"
                class="col-md-9 camps"
            >
                <h1>
                    Amenity Rental
                    <a
                        :href="facilitiesUrl"
                        class="btn btn-default btn-xs pull-right"
                    >
                        Switch to Facility
                    </a>
                </h1>

                <div v-if="filteredSites.length">
                    <site-card
                        v-for="site in filteredSites"
                        :key="site.id"
                        :site="site"
                        :view-url-format="viewUrl"
                    />
                </div>
                <div v-else>
                    <h2>We are sorry but could not find any sites matching the search criteria you entered. Please try your search again.</h2>
                </div>
            </div>
            <div
                v-else
                class="col-md-9"
            >
                <h2 class="text-center">
                    Loading Campsites <i class="fa fa-spinner fa-spin" />
                </h2>
            </div>
        </div>
    </div>
</template>

<script>
import AmenityFilter from './amenity-view-filter.vue'
import SiteCard from './site-card'

export default {
    components: {
        AmenityFilter,
        SiteCard,
    },
    props: {
        filters: {
            type: Object,
            required: true,
        },
        urls: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        busy: false,
        currentFilter: {
            'sleeping-capacity': {},
            'amenities': {},
            'cost': {},
            'participants': {
                adult: '',
                youth: ''
            },
            'dates': {
                arrival_date: '',
                departure_date: ''
            },
            'id': {}
        },
        sites: [],
    }),

    computed: {
        sitesUrl () {
            return _.get(this.urls, 'amenitySites')
        },
        facilitiesUrl () {
            return _.get(this.urls, 'facilities')
        },
        viewUrl () {
            return _.get(this.urls, 'view')
        },
        filteredSites () {
            const sites = _.chain(this.sites)
                .filter(this.isSleepingCapacity)
                .filter(this.hasAmenities)
                .filter(this.isCost)
                .filter(this.isFacility)
                .filter(this.isParticipants)
                // .filter(this.isInDateRange)
                .value()

            return sites
        },
        // noSites () {
        //     return !this.sites.length || !this.filteredSites.length
        // },
    },
    created () {
        this.requestSites()
    },
    methods: {
        requestSites () {
            this.busy = true
            $.get(this.sitesUrl)
                .done(this.updateSites)
                .always(() => {
                    this.busy = false
                })
        },
        updateSites (data) {
            this.sites = _.cloneDeep(data)
        },

        // Filter Methods
        isSleepingCapacity (site) {
            const siteLimit = _.get(site, 'campsite_limit')
            const max = _.chain(this.currentFilter)
                .cloneDeep()
                .get('sleeping-capacity')
                .pickBy()
                .keys()
                .map(Number)
                .max()
                .toSafeInteger()
                .value()

            return (max === 0) ? true : siteLimit >= max
        },
        hasAmenities (site) {
            const filters = _.chain(this.currentFilter)
                .cloneDeep()
                .get('amenities')
                .pickBy()
                .keys()
                .value()

            const matches = _.chain(site)
                .pick(filters)
                .filter()
                .some()
                .value()

            return _.isEmpty(filters) ? true : matches
        },
        isCost (site) {
            const cost = _.get(site, 'first_day_cost')
            const filter = _.chain(this.currentFilter)
                .cloneDeep()
                .get('cost')
                .pickBy()
                .keys()
                .map(Number)
                .value()

            if (_.isEmpty(filter) || filter.length === 2) {
                return true
            }

            return (_.max(filter) === 1) ? cost <= 50 : cost > 50
        },
        isFacility (site) {
            const filter = _.get(this.currentFilter, 'id')
            const value = _.get(site, 'id')
            const filterId = _.toSafeInteger(filter.id)
            return _.isEmpty(filter) ? true : value === filterId
        },
        isParticipants (site) {
            const siteLimit = _.get(site, 'campsite_limit')
            const total = _.chain(this.currentFilter)
                .get('participants')
                .map(_.toSafeInteger)
                .sum()
                .value()

            return (total === 0) ? true : siteLimit >= total
        },
        // isInDateRange (campsite) {
        //     let arrivalDate = _.get(this.currentFilters, 'dates.arrival_date', null)
        //     let departureDate = _.get(this.currentFilters, 'dates.departure_date', null)
        //     if (!arrivalDate || !departureDate) {
        //         return true
        //     }
        //     arrivalDate = new Date(arrivalDate)
        //     departureDate = new Date(departureDate)
        //     const parentCampsiteId = campsite.parent_campsite_id
        //     const childCampsiteIds = _.map(campsite.child_campsites, 'id')
        //     const eventsLength = _.chain(campsite.campsite_registration_building)
        //         .filter(this.buildings(arrivalDate, departureDate))
        //         .filter(this.eventsForDates(parentCampsiteId, childCampsiteIds, arrivalDate, departureDate))
        //         .value()
        //         .length
        //     return eventsLength == 0
        // },

        // buildings (arrivalDate, departureDate) {
        //     return function (building) {
        //         return (new Date(building.arrival_date) < departureDate && new Date(building.departure_date) > arrivalDate)
        //     }
        // },
        // eventsForDates (parentId, childIds, arrivalDate, departureDate) {
        //     return function (building) {
        //         const comp1 = new Date(building.arrival_date) <= departureDate
        //         const comp2 = new Date(building.departure_date) >= arrivalDate
        //         const comp3 = building.campsite_id == parentId
        //         const comp4 = _.includes(childIds, building.campsite_id)
        //         return ((comp1 && comp2 && comp3) || (comp1 && comp2 && comp4))
        //     }
        // },
    },
}
</script>
