<template>
    <div class="equipment-rentals">
        <!-- ---------- Panel for rentals -->
        <div class="col-lg-12">
            <div class="panel panel-primary">
                <div class="panel-heading">
                    <strong class="panel-heading-text">Additional Equipment &amp; Program Facility Rental</strong>
                </div>
                <div class="panel-body">
                    <div class="table-responsive">
                        <table class="table">
                            <tr>
                                <th>Item</th>
                                <th>Participants</th>
                                <th>Unit Price</th>
                                <th>Minimum Cost</th>
                                <th>Total</th>
                            </tr>
                            <tr v-for="(charge, index) in charges">
                                <td class="transaction-item">
                                    <div class="form-group">
                                        <select
                                            v-model="charge.item_id"
                                            class="form-control"
                                            :name="'equipment_rentals[' + index + '][id]'"
                                            @change="updateItem(charge)"
                                        >
                                            <option
                                                v-for="rental in rentals"
                                                :value="rental.id"
                                            >
                                                {{ rental.item }}
                                            </option>
                                        </select>
                                    </div>
                                </td>
                                <input
                                    v-model="charge.item_name"
                                    type="hidden"
                                    :name="'equipment_rentals[' + index + '][name]'"
                                >
                                <td class="transaction-item">
                                    <div class="form-group">
                                        <input
                                            v-model="charge.quantity"
                                            type="number"
                                            :name="'equipment_rentals[' + index + '][quantity]'"
                                            class="form-control"
                                            @change="updateItemTotal(charge)"
                                        >
                                    </div>
                                </td>
                                <td class="transaction-item">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-addon">
                                                $
                                            </div>
                                            <input
                                                v-model="charge.price"
                                                readonly
                                                type="number"
                                                :name="'equipment_rentals[' + index + '][price]'"
                                                class="form-control"
                                                @change="updateItemTotal(charge)"
                                            >
                                        </div>
                                    </div>
                                </td>
                                <td class="transaction-item">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-addon">
                                                $
                                            </div>
                                            <input
                                                v-model="charge.minimum_cost"
                                                readonly
                                                type="number"
                                                :name="'equipment_rentals[' + index + '][minimum_cost]'"
                                                class="form-control"
                                            >
                                        </div>
                                    </div>
                                </td>
                                <td class="transaction-item">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-addon">
                                                $
                                            </div>
                                            <input
                                                v-model="charge.total"
                                                readonly
                                                type="number"
                                                :name="'equipment_rentals[' + index + '][total]'"
                                                class="form-control"
                                            >
                                        </div>
                                    </div>
                                </td>
                                <td class="align-top">
                                    <a
                                        class="btn btn-danger"
                                        href="#"
                                        @click.prevent="deleteCharge(index)"
                                    >
                                        <i class="fa fa-trash-o" />
                                    </a>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="pull-right">
                        <a
                            href="#"
                            class="btn btn-default btn-xs"
                            @click.prevent="addCharge"
                        >
                            <i
                                class="fa fa-plus"
                                aria-hidden="true"
                            />
                            Add Rental
                        </a>
                    </div>
                    <input
                        v-for="charge in deletedCharges"
                        type="hidden"
                        :value="charge.id"
                        name="deletedRentals[]"
                    >
                </div>
            </div>
        </div>
        <!-- ---------- End Panel for rentals -->
    </div>
</template>

<script>
export default {
    props: ['equipmentRentals', 'campsiteRegistrationRentals', 'noFee', 'old'],

    data () {
        return {
            charges: [],
            deletedCharges: [],
            rentals: this.equipmentRentals,
            registrationRentals: this.campsiteRegistrationRentals,
            total: 0
        }
    },

    watch: {
        noFee () {
            this.zeroCosts()
        }
    },

    mounted () {
        var oldEq = this.old.equipment_rentals
        if (!_.isEmpty(oldEq)) {
            _.forEach(oldEq, (value) => {
                _.assign(value, { item_id: value.id, item_name: value.name })
            })
            this.charges = oldEq
            this.emitTotalCost()
            return
        }
        if (this.registrationRentals) {
            var equipmentRental
            this.registrationRentals.forEach((registration) => {
                // Find the equipment
                equipmentRental = this.rentals.find((rental) => {
                    return rental.id === registration.equipment_rental_id
                })

                // add new charge
                this.charges.push({
                    id: registration.id,
                    item_id: equipmentRental.id,
                    item_name: equipmentRental.item,
                    quantity: registration.quantity,
                    price: equipmentRental.price,
                    total: registration.total,
                    minimum_cost: equipmentRental.minimum_cost
                })
            })

            this.emitTotalCost()
        } else {
            this.addCharge()
        }
    },

    methods: {
        addCharge () {
            this.charges.push({ id: null, item_id: null, item_name: null, quantity: 0, price: 0, total: 0, minimum_cost: 0 })
        },

        deleteCharge (index) {
            // Checks if the charge existed before the session was created or if it was another row added
            if (this.charges[index].id) {
                this.deletedCharges.push(this.charges[index])
            }

            this.charges.splice(index, 1)
        },

        // Takes the id of the selected item to update the price and total
        updateItem (charge) {
            if (charge.item_id) {
                var equipmentRental
                // Finds the rental item that is selected so totals can be computed
                this.rentals.forEach(function (rental, i) {
                    if (rental.id === charge.item_id) {
                        equipmentRental = rental
                    }
                })

                charge.price = equipmentRental.price
                charge.minimum_cost = equipmentRental.minimum_cost
                charge.item_name = equipmentRental.item
            }

            this.updateItemTotal(charge)

            return charge
        },

        updateItemTotal (charge) {
            var workingTotal

            workingTotal = charge.quantity * charge.price

            charge.total = (this.noFee)
                ? 0
                : (workingTotal < charge.minimum_cost)
                    ? charge.minimum_cost
                    : workingTotal

            this.emitTotalCost()

            return charge
        },

        emitTotalCost () {
            var runningTotal = this.total

            this.charges.forEach(function (charge) {
                runningTotal += parseInt(charge.total)
            })

            this.$emit('totalUpdated', runningTotal)
        },

        zeroCosts () {
            if (this.noFee) {
                this.charges.forEach((rental) => {
                    rental.total = 0
                })

                this.total = 0
            } else {
                this.charges.forEach((rental) => {
                    this.updateItemTotal(rental)
                })
            }
        }
    }
}
</script>
