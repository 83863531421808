<template>
    <div class="row">
        <div class="col-md-6">
            <input
                v-model="date"
                type="text"
                class="form-control"
                @focus="showCalendar = true"
            >
            <calendar
                v-if="showCalendar"
                :class="name + '_calendar'"
                :available-dates="formattedAvailableDates"
                :event-days="formattedEventDays"
                @blur="showCalendar = false"
            />
        </div>

        <div class="col-md-6">
            <div class="input-group bootstrap-timepicker timepicker">
                <input
                    ref="timepicker"
                    type="text"
                    class="form-control input-small timepicker-input"
                    :value="time"
                    @blur="updateValue()"
                >
                <span class="input-group-addon"><i class="glyphicon glyphicon-time" /></span>
            </div>
        </div>

        <input
            type="hidden"
            :name="name"
            :value="finalValue"
        >
    </div>
</template>
<script>
import moment from 'moment'
var _ = require('lodash')

export default {
    props: ['value', 'initialTime', 'name', 'eventDays', 'availableDates'],
    data: function () {
        return {
            date: '',
            time: '12:00 AM',
            finalValue: ' 12:00 AM',
            showCalendar: false,
            formattedAvailableDates: this.availableDates,
            formattedEventDays: this.eventDays,
        }
    },
    watch: {
        date: function () {
            this.finalValue = this.date + ' ' + this.time
        },
        time: function () {
            this.finalValue = this.date + ' ' + this.time
        },
        eventDays: function () {
            var disabledDates = []
            this.formatDates()

            // Restrict first 3 days
            var datesToRestrict = []
            for (let i = 0; i < 4; i++) {
                var available = this.availableDates[i]
                var restrict = (moment().add(i, 'days').toDate())
                if (available.toDateString() === restrict.toDateString()) {
                    datesToRestrict.push(i)
                }
            }
            _.pullAt(this.availableDates, datesToRestrict)

            if (this.eventDays) {
                this.eventDays.map(function (day) {
                    disabledDates.push(day.Date)
                })
            }
            $(this.$refs.datepicker).datepicker('setDatesDisabled', disabledDates)
        },

        availableDates: function () {
            this.formatDates()
        }
    },
    mounted () {
        var disabledDates = []

        this.formatDates()

        if (this.eventDays) {
            this.eventDays.map(function (day) {
                disabledDates.push(day.Date)
            })
        }

        if (this.value) {
            this.date = moment(this.value).format('M/D/YYYY')
            this.time = moment(this.value).format('h:mm A')
        }

        var vue = this
        $(this.$refs.datepicker)
            .datepicker({
                startDate: '',
                datesDisabled: disabledDates
            })
            .on('changeDate', function (e) {
                vue.date = e.format('mm/dd/yyyy')
            })
        $(this.$refs.timepicker).timepicker({ defaultTime: '12:00AM' })
            .on('changeTime.timepicker', function (e) {
                vue.time = e.time.value
            })
    },
    created: function () {
        const self = this
        const currentCalendarClass = '.' + $.escapeSelector(this.name) + '_calendar'

        // close calendar dropdown when clicked outside of the dropdown
        window.addEventListener('click', function (e) {
            // polyline and svg have vanilla js event listeners that destroy the calendar, so if those are clicked keep showing the dropdown
            if (!self.$el.contains(e.target) && e.target.localName !== 'polyline' && e.target.localName !== 'svg') {
                self.showCalendar = false
            }
        })

        // Listen for selected dates from the calendar
        $(document).on('click', currentCalendarClass + ' .date-available', function () {
            self.showCalendar = false
            self.date = moment(new Date(this.id)).format('M/D/YYYY')
            self.time = moment(new Date(this.id)).format('h:mm A')
            self.finalValue = self.date + ' ' + self.time
            self.updateValue()
        })
    },
    methods: {
        updateValue () {
            this.$emit('input', this.finalValue)
        },

        formatDates () {
            this.formattedEventDays = this.eventDays
            this.formattedAvailableDates = this.availableDates

            if (this.availableDates != null && this.eventDays != null) {
                this.availableDates.forEach(function (date, index) {
                    this[index] = new Date(date)
                }, this.formattedAvailableDates)

                this.eventDays.forEach(function (item, index) {
                    this[index].Date = new Date(item.Date)
                }, this.formattedEventDays)
            }
        },
    }
}
</script>
