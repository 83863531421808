<template>
    <div>
        <div class="panel panel-primary">
            <div class="panel-heading">
                <strong>Register By Troop</strong>
            </div>
            <div class="panel-body">
                <div class="form-group col-sm-4">
                    <label for="session">Select Session*</label>
                    <select v-model="selectedSession" class="form-control">
                        <option disabled value>Please select one</option>
                        <option v-for="session in sessions" :value="session"
                        >{{ session.session_name }}</option>
                    </select>
                </div>
                <div class="form-group col-sm-4">
                    <label for="troop">Select Troop*</label>
                    <select v-model="selectedTroop" class="form-control">
                        <option disabled value>Please select one</option>
                        <option
                            v-for="troop in filteredTroops"
                            :value="troop.id"
                        >{{ troop.build_name }}</option>

                    </select>
                </div>
                <div class="form-group col-sm-4">
                    <label for="scout">Select Scout*</label>
                    <select v-model="selectedScout" class="form-control">
                        <option disabled value>Please select one</option>
                        <option
                            v-for="scouter in filteredScouts"
                            :value="scouter"
                        >{{ scouter.last_name }}, {{ scouter.first_name }}</option>
                    </select>
                </div>
            </div>
            <div class="panel-footer">
                <button  v-on:click="clear" class="btn btn-danger" type="clear"><i class="fa fa-undo" aria-hidden="true"></i> Clear Filters</button>
            </div>
        </div>
        <register-by-troop-form
            :scout="selectedScout"
            :selected-session="selectedSession.session_name"
            :selected-troop="selectedTroop"
            :weekly-classes="filteredClasses"
            :url="url"
        ></register-by-troop-form>
    </div>
</template>

<script>
    import RegisterByTroopForm from './register-by-troop-form.vue'

    export default {
        props: [ 'rootUrl', 'scouters', 'sessions', 'troops', 'weeklyClasses'],

        components: { RegisterByTroopForm },

        data () {
            return {
                selectedSession: '',
                selectedTroop: '',
                selectedScout: '',
                url: this.rootUrl,
            }
        },

        computed: {
            // Uses ordered troops in the filter
            orderedTroops: function () {
                return _.orderBy(this.troops, 'number')
            },
            filteredTroops () {
                if (this.selectedSession == '') {
                    return []
                }
                return _.filter(this.orderedTroops, troop => {
                    return troop.camp_session_id === this.selectedSession.id
                })
            },

            // Scouts ordered by last_name in Controller
            filteredScouts () {
                if (this.selectedTroop == '') {
                    return []
                }
                return _.filter(this.scouters, scout => {
                    return scout.summer_troop_id === this.selectedTroop
                })
            },


            orderedClasses: function () {
                return _.orderBy(this.weeklyClasses, 'summer_schedule.course.class_name')
            },
            filteredClasses () {
                if (this.selectedSession == '') {
                    return this.orderedClasses
                }
                return _.filter(this.orderedClasses, weeklyClass => {
                    return weeklyClass.camp_session_id === this.selectedSession.id
                })
            },
        },

        methods: {
            clear: function (event) {
                this.selectedSession = ''
                this.selectedTroop = ''
                this.selectedScout = ''
            }
        },

        watch: {
            selectedTroop: function (selectDefault) {
                if (this.selectedSession != '') {
                    this.selectedScout = this.filteredScouts[0]
                }
            }
        },
    }
</script>
