<template>
    <tr>
		<td>{{weeklyClass.summer_schedule.course.class_name}}</td>
		<td>{{weeklyClass.summer_schedule.day.days}}</td>
		<td>{{weeklyClass.summer_schedule.start_time | moment}}-{{weeklyClass.summer_schedule.end_time | moment}}</td>
		<td class="text-center">
			<span class="badge">
				{{weeklyClass.mb_enrolls_count}}/{{weeklyClass.class_size}}
			</span>
		</td>
		<td v-if="hasClass.length">
			<div class="alert alert-info table-alert text-center" role="alert">
				  <i class="fa fa-check-circle" aria-hidden="true"></i>
				  <strong> Active</strong>
			</div>
		</td>
		<td v-else-if="weeklyClass.mb_enrolls_count != weeklyClass.class_size">
            <button
                @click.prevent="handler"
                class="btn btn-success table-btn"
                type="submit"
                v-bind:disabled="saving"
            >
                <div v-if="saving">
                    <i v-if="saving" class="fa fa-spinner fa-spin"></i>
                    SAVING
                </div>
                <div v-else>
                    <i class="fa fa-plus" aria-hidden="true"></i> Add
                </div>
            </button>
		</td>
		<td v-else>
			<div class="alert alert-danger table-alert text-center" role="alert">
				  <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
				  <span class="sr-only">Error:</span>
				  <strong>Full</strong>
			</div>
		</td>
	</tr>
</template>

<script>
	import moment from 'moment'

    export default {
    	components: { moment },

        props: [ 'scout', 'weeklyClass', 'url' ],

        data () {
            return {
                saving: false,
                newClass: {
                    scouterId: null,
                    weeklyClassId: null,
                },
            }
        },

        computed: {
            newClassData () {
                return {
                    scouter_id: this.scout.id,
                    weekly_class_id: this.weeklyClass.id,
                }
            },

            hasClass () {
                return _.filter(this.scout.mb_enrolls, mbEnroll => {
                    return mbEnroll.weekly_class_id === this.weeklyClass.id
                })
            }
        },

		filters: {
			moment: function (date) {
			    return moment(date, "h:mm:ss").format('h:mmA');
			}
		},

        methods: {
			moment: function () {
			    return moment();
			},
            save () {
                this.saving = true
                $.ajax({
                    url: this.url + '/add',
                    method: 'post',
                    data: this.newClassData,
                })
                .done(data => this.addNewClass(data))
                .always(() => {
                    console.log('saving finished')
                    this.saving = false
                })
            },
            addNewClass (data) {
                this.scout.mb_enrolls.push(data)
                this.newClass = {
                    scouterId: null,
                    weeklyClassId: null,
                }
            },
            handler: function () {
                this.save();
                this.$emit('add-enrollment');
            }
        },
    }
</script>
