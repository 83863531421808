<template>
    <tr>
		<td>{{ schedule.class_name }}</td>
		<td>{{ schedule.start | moment }}-{{ schedule.end | moment }}</td>
		<td>{{ schedule.days }}</td>
		<td>
            <button @click="handler" type="button" class="btn btn-danger table-btn" aria-label="Close">
                <span v-if="!removing" aria-hidden="true"><i class="fa fa-trash" aria-hidden="true"></i> Remove</span>
                <i v-if="removing" class="fa fa-spinner fa-spin"></i>
            </button>
		</td>
    </tr>
</template>

<script>
	import moment from 'moment'

    export default {
    	components: { moment },

        props: {
        	schedule: {
        		type: Object,
        		required: true,
        	},
            remove: {
                type: Function,
                required: true,
            },
            url: {
                type: String,
                required: true,
            },
        },

        data () {
            return {
                removing: false,
            }
        },

		methods: {
		  	moment: function () {
		    	return moment();
		  	},
            removeClassRequest () {
                this.removing = true
                $.ajax({
                    url: this.url + `/drop/${this.schedule.id}`,
                    method: 'delete',
                })
                .done(() => this.removeClass())
                .always(() => {
                    this.removing = false
                })
            },
            removeClass () {
                this.remove(this.schedule)
            },
            handler: function () {
                this.removeClassRequest();
                this.$emit('drop-enrollment');
            }
		},

		filters: {
		  moment: function (date) {
		    return moment(date, "h:mm:ss").format('h:mmA');
		  }
		}
    }
</script>
