
import Vue from 'vue'
import FacilityView from './components/camp-site-register/facility-view.vue'
import AmenityView from './components/camp-site-register/amenity-view.vue'
import Calendar from './components/camp-site-register/calendar.vue'
import EquipmentRentals from './components/camp-site-register/equipment-rentals.vue'
import DatetimePicker from './components/modules/datetime-picker.vue'
import RegisterByTroop from './components/register-by-troop.vue'
import Transactions from './components/transactions.vue'

global.jQuery = global.$ = require('jquery')
require('bootstrap-datepicker/dist/js/bootstrap-datepicker.js')
require('bootstrap-timepicker/js/bootstrap-timepicker.js')
require('bootstrap-sass/assets/javascripts/bootstrap.js')
require('lodash/lodash.js')

Vue.component('facility-view', FacilityView)
Vue.component('amenity-view', AmenityView)
Vue.component('datetime-picker', DatetimePicker)
Vue.component('calendar', Calendar)
Vue.component('equipment-rentals', EquipmentRentals)
Vue.component('register-by-troop', RegisterByTroop)
Vue.component('transactions', Transactions)

new Vue({ el: '#vue' })

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
    }
})

$('.select-filter').change(function () {
    var url = $(this).val()
    window.location = url
})

$('.date').datepicker()

$(() => {
    $(document).on('click', '[data-submit-action]', event => {
        event.preventDefault()
        var $this = $(event.target)
        var data = $this.data('submitAction')
        var confirmed = (!_.has(data, 'message')) ? true : window.confirm(data.message)

        if (confirmed) {
            var $form = $this.closest('form')
            var $method = $form.find('input[name="_method"]')

            if ($method.length === 0) {
                $form.append('<input type="hidden" name="_method">')
                $method = $form.find('input[name="_method"]')
            }

            $method.val(data.method)
            $form
                .attr('action', data.action)
                .trigger('submit')
        }
    })
})
