<template>
    <div class="register-by-troop-form">
        <div v-if="isComplete">
            <div class="col-lg-5">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-lg-12">
                                <strong>{{ scout.first_name }} {{ scout.last_name }}</strong>'s Schedule
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Course</th>
                                    <th>Start-End</th>
                                    <th>Days</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    is="scouter-class-row"
                                    v-for="schedule in filteredSchedules"
                                    :key="schedule.id"
                                    :remove="remove"
                                    :schedule="schedule"
                                    :url="url"
                                    @drop-enrollment="dropEnrollment(schedule.weekly_class_id)"
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <span>Classes During {{ selectedSession }}</span>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Days</th>
                                    <th>Start-End</th>
                                    <th class="text-center">
                                        Enrolled/Max
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    is="weekly-class-row"
                                    v-for="weeklyClass in weeklyClasses"
                                    :key="weeklyClass.id"
                                    :weekly-class="weeklyClass"
                                    :scout="scout"
                                    :url="url"
                                    @add-enrollment="addEnrollment(weeklyClass.id)"
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import moment from 'moment'
import ScouterClassRow from './scouter-class-row.vue'
import WeeklyClassRow from './weekly-class-row.vue'

export default {

    components: { draggable, ScouterClassRow, WeeklyClassRow },
    filters: {
        moment: function (date) {
            return moment(date, 'h:mm:ss').format('h:mmA')
        }
    },
    props: ['schedules', 'selectedSession', 'scout', 'selectedTroop', 'weeklyClasses', 'url'],

    computed: {
        isComplete () {
            if (this.selectedSession != null && this.selectedTroop != '') {
                return true
            }
            return false
        },
        filteredSchedules: function () {
            if (this.scout == '') {
                return null
            }
            return this.scout.mb_enrolls
        },
    },
    methods: {
        remove: function (schedule) {
            const index = _.findIndex(this.scout.mb_enrolls, { id: schedule.id })
            this.scout.mb_enrolls.splice(index, 1)
        },
        addEnrollment: function (id) {
            const updatedClass = _.find(this.weeklyClasses, ['id', id])
            updatedClass.mb_enrolls_count = updatedClass.mb_enrolls_count + 1
        },
        dropEnrollment: function (id) {
            const updatedClass = _.find(this.weeklyClasses, ['id', id])
            updatedClass.mb_enrolls_count = updatedClass.mb_enrolls_count - 1
        },
        moment: function () {
            return moment()
        },
    },

}
</script>
