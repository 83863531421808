<template>
    <div class="col-md-3 filter">
        <form>
            <h3>Filter By</h3>
            <div
                v-for="filter, key in filters"
                :key="key"
            >
                <h5>{{ filter.name }}</h5>
                <div
                    v-for="filterValue in filter.values"
                    :key="filterValue.id"
                >
                    <label v-if="filterValue.id !== 'id'">
                        <input
                            type="checkbox"
                            :disabled="busy"
                            :value="filterValue.id"
                            @input="updateCheckbox(key, $event)"
                        >
                        <span>{{ filterValue.name }}</span>
                    </label>
                    <select
                        v-else
                        v-model="facility"
                        class="filter-campsites"
                        :disabled="busy"
                    >
                        <option disabled>
                            Select a facility
                        </option>
                        <option
                            v-for="option, index in filterValue.options"
                            :key="index"
                            :value="index"
                        >
                            {{ option }}
                        </option>
                    </select>
                </div>
            </div>
        </form>

        <div class="btn-group">
            <button
                :disabled="busy"
                class="btn btn-warning"
                @click.stop.prevent="reset"
            >
                Clear Filters
            </button>
        </div>
    </div>
</template>

<script>
export default {
    components: {
    },
    props: {
        filters: {
            required: true,
            type: Object,
        },
        value: {
            required: true,
            type: Object,
        },
        busy: {
            type: Boolean,
            default: () => false,
        },
    },

    computed: {
        facility: {
            get () {
                return _.get(this.value, 'id.id', '')
            },
            set (value) {
                this.update('id.id', value)
            },
        }
    },

    methods: {
        updateCheckbox (key, event) {
            const checked = event.target.checked
            const value = event.target.value
            this.update([key, value], checked)
        },
        update (path, value) {
            const filter = _.chain(this.value)
                .cloneDeep()
                .set(path, value)
                .value()
            this.$emit('input', filter)
        },
        reset () {
            this.$emit('input', {
                'camp-property': {},
                'facility-type': {},
                'season-usage': {},
                'cost-type': {},
                'usage-type': {},
                'id': {},
            })
        },
    },
}
</script>
