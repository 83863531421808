<template>
    <div
        ref="calendar"
        :class="viewLink == null ? 'cld-dropdown' : ''"
    />
</template>

<script>
import moment from 'moment'
import calendar from '@/vendor/calendar'

export default {
    props: ['availableDates', 'eventDays', 'viewLink'],
    mounted () {
        var eventsForCalendar = []
        var viewLink = this.viewLink
        var usedDates = []
        $.each(this.availableDates, function (k, v) {
            if (!(v instanceof Date)) {
                v = new Date(v)
            }
            var title = 'Book'
            var link = viewLink + '?date=' + (v.getMonth() + 1) + '-' + v.getDate() + '-' + v.getFullYear() + '#booking'
            if (moment(v).format('HH:mm:ss').toString() != '00:00:00') {
                title += ' after ' + moment(v).format('h:mm: A')
                link += ' ' + moment(v).hours() + ':' + moment(v).minutes() + ':' + moment(v).seconds()
                v = moment(v).toDate()
            }

            if (viewLink == null) {
                link = ''
            }

            eventsForCalendar[k] = {
                Date: v,
                Title: title,
                Link: link
            }
        })

        $.each(this.eventDays, function (k, v) {
            v.Date = new Date(v.Date)

            // Ensures there aren't duplicates on the event days
            if (!usedDates.indexOf(v.Date.toString()) >= 0) {
                eventsForCalendar.push(v)
                usedDates.push(v.Date.toString())
            }
        })

        var settingsForCalendar = {
            hidePreviousMonths: true,
            EventTargetWholeDay: true
        }

        calendar(this.$refs.calendar, eventsForCalendar, settingsForCalendar)
    },
    methods: {}
}
</script>
